import App from "@iso/redux/app/reducer";
import articlesReducer from "@iso/redux/articles/reducer";
import bannersReducer from "@iso/redux/banners/reducer";
import checklistNotesReducer from "@iso/redux/checklist-notes/reducer";
import commentsReducer from "@iso/redux/comments/reducer";
import contactsReducer from "@iso/redux/contacts/reducer";
import dashboardReducer from "@iso/redux/dashboard/reducer";
import LanguageReducer from "@iso/redux/languages/reducer";
import mediaReducer from "@iso/redux/medias/reducer";
import menuReducer from "@iso/redux/menu/reducer";
import pagesReducer from "@iso/redux/pages/reducer";
import questionSubscriptionReducer from "@iso/redux/questionSubscription/reducer";
import questionsReducer from "@iso/redux/questions/reducer";
import settingsReducer from "@iso/redux/settings/reducer";
import subscriptionsReducer from "@iso/redux/subscriptions/reducer";
import tagsReducer from "@iso/redux/tags/reducer";
import themeReducer from "@iso/redux/themeSwitcher/reducer";
import trainingsReducer from "@iso/redux/trainings/reducer";
import usersReducer from "@iso/redux/users/reducer";
import { combineReducers } from "redux";
import landingsReducer from "@iso/redux/landings/reducer";
import transactionsReducer from "@iso/redux/transactions/reducer";
import materialsReducer from "@iso/redux/materials/reducer";
import venquestionsReducer from "@iso/redux/venquestions/reducer";
import vensubscriptionsReducer from "@iso/redux/vensubscriptions/reducer";
import venfaqsReducer from "@iso/redux/venfaq/reducer";
import extradeclarationsReducer from "@iso/redux/extradeclarations/reducer";
import statisticsReducer from "@iso/redux/statistics/reducer";
import contentsReducer from "@iso/redux/contents/reducer";

const reducers = combineReducers({
  Auth: usersReducer,
  Articles: articlesReducer,
  Banners: bannersReducer,
  Questions: questionsReducer,
  Pages: pagesReducer,
  Tags: tagsReducer,
  Settings: settingsReducer,
  Trainings: trainingsReducer,
  ThemeSwitcher: themeReducer,
  CommentsReducer: commentsReducer,
  LanguageReducer,
  SubscriptionsReducer: subscriptionsReducer,
  ContactsReducer: contactsReducer,
  MenuReducer: menuReducer,
  MediaReducer: mediaReducer,
  QuestionSubscriptionReducer: questionSubscriptionReducer,
  DashboardReducer: dashboardReducer,
  checklistNotesReducer: checklistNotesReducer,
  LandingsReducer: landingsReducer,
  TransactionsReducer: transactionsReducer,
  Materials: materialsReducer,
  Venquestions: venquestionsReducer,
  Vensubscriptions: vensubscriptionsReducer,
  Venfaqs: venfaqsReducer,
  Extradeclarations: extradeclarationsReducer,
  Statistics: statisticsReducer,
  Contents: contentsReducer,
  App: App,
});

export default reducers;
