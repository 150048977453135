export const GET_CONTENTS_REQUEST = "GET_CONTENTS_REQUEST";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";

export const SHOW_CONTENT_REQUEST = "SHOW_CONTENT_REQUEST";
export const SHOW_CONTENT_SUCCESS = "SHOW_CONTENT_SUCCESS";

export const POST_CONTENT_REQUEST = "POST_CONTENT_REQUEST";
export const POST_CONTENT_SUCCESS = "POST_CONTENT_SUCCESS";

export const PUT_CONTENT_REQUEST = "PUT_CONTENT_REQUEST";
export const PUT_CONTENT_SUCCESS = "PUT_CONTENT_SUCCESS";

export const DELETE_CONTENT_REQUEST = "DELETE_CONTENT_REQUEST";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
