import * as ACTIONS from "@iso/redux/contents/actionTypes";

const initialState = {
  contents: {
    list: [],
    total: 0,
  },
};

function contentsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.GET_CONTENTS_SUCCESS: {
      return {
        ...state,
        ...{ contents: payload },
      };
    }

    default:
      return state;
  }
}

export default contentsReducer;
